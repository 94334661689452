import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import Section from '../components/Section';
import Img from 'gatsby-image';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  min-height: 100%;

  h1 {
    text-align: center;
  }
`;

const About = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  p:last-child {
    margin: 0;
  }
`;

const Photo = styled.div`
  flex: 1;
  text-align: center;
  min-width: 280px;
  flex-shrink: 0;
  margin: 0 0 40px;

  @media (min-width: 560px) {
    margin: 0;
  }
`;

const Pic = styled.span`
  display: block;
  width: 72%;
  position: relative;
  margin: 0 auto;
  img {
    border-radius: 50%;
    display: block;
    width: 100%;
    margin: 0;
  }
`;

const Info = styled.div`
  flex: 1;
  align-items: center;
  min-width: 200px;

  p {
    max-width: 400px;
  }
`;

export default class AboutPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Page>
          <Section>
            <About>
              <Photo>
                <Pic>
                  {/* <Img sizes={p.sizes} /> */}
                  {/* <img alt="" src="/p.jpg" /> */}
                  <Img
                    sizes={this.props.data.file.childImageSharp.sizes}
                    alt="Таня Сахарова, saharovata"
                  />
                </Pic>
              </Photo>
              <Info>
                {/* <p>Приветствую! Меня зовут Таня Сахарова. Я фотохудожник.</p>

                <p>Живу в Москве, снимаю по всему миру.</p>

                <p>
                  Рассказываю через фотографию истории о людях и сохраняю
                  особенные для них моменты жизни.
                </p>

                <p>
                  Со мной просто, — будьте собой, а я постараюсь уловить ваше
                  настоящее. А поскольку я по натуре визуал, то сделаю это
                  красиво.
                </p>

                <p>
                  В процессе веду себя мягко и корректно, мне важно чтобы вы
                  чувствовали себя комфортно.
                </p>

                <p>
                  Если мое творчество отзывается у вас, давайте творить вместе.
                </p>

                <p>
                  <a href="mailto:saharovata@gmail.com">saharovata@gmail.com</a>
                </p> */}
                <p>Приветствую!</p>
                <p>
                  Меня зовут Таня Сахарова.
                  <br />Я фотограф. Фотографирую портреты.
                </p>
                <p>
                  Живу в Москве, снимаю по всему миру.</p><p> Последние несколько лет
                  проходят под эгидой - путь к себе, поиск внутренних опор,
                  самореализация. Своим опытом и видением я делюсь в своём
                  творчестве.
                </p>
                <p>
                  Опоры и поддержку я нахожу в проживании контакта с природой.{' '}
                  <br />
                  Как в широком понимании этого слова: флора и фауна, леса,
                  горы, реки и так далее; наблюдение явлений окружающего мира,
                  например, таких как ветер, дождь, закат, смена времён года.{' '}
                  <br />
                  Так и в контексте природного в человеке: контакт с телом,
                  ощущениями, состоянием; контакт со своими чувствами, эмоциями.
                  <br />
                  Существует также социальная природа, — человеческие отношения,
                  социальные роли, архетипы. На своих съёмках я даю место
                  проявиться этим составляющим. <br />А поскольку я по натуре
                  визуал, сделаю это красиво.
                </p>

                <p>
                  Приглашаю таких же как я исследователей увидеть себя,
                  запечатлеть этап своего пути бережным принимающим взглядом.
                </p>

                <p>
                  <a href="/terms">Условия и запись на съемку</a>
                </p>
                <p>
                  <a href="/shot">Подробнее о формате съемки</a>
                </p>
              </Info>
            </About>
          </Section>
        </Page>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    file(relativePath: {eq: "p.jpg"}) {
      childImageSharp {
        sizes(maxWidth: 1200, quality: 95) {
          src
          srcSet
          sizes
          base64
          aspectRatio
        }
      }
    }
  }
`;
